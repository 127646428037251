import { useEffect, useState } from "react";
import axios from "axios";

axios.interceptors.request.use(res => {
	res.headers['x-access-token'] = localStorage.getItem('token');
	return res;
});

export const useAuthentication = (authenticatedCallback: Function, nonAuthCallback: Function) => {
	useEffect(() => {
		axios.get('/api/authorized').then(() => {
			authenticatedCallback();
		}).catch(e => {
			nonAuthCallback();
		});
	}, []);
};
