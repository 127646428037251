import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { format } from 'date-fns';
import { from } from 'rxjs';
import { takeWhile, scan, filter, take, map } from 'rxjs/operators';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './Budget.scss';
import Modal from './Modal';

class Budget extends Component<any, any> {

	constructor(props: any) {
		super( props );
		this.state = {
			items: [],
			tithingOwed: 0,
			lastTithing: null,
			lastFastOffering: null,
			lastFastOfferingAmount: 0,
			modalOpen: false,
			itemDate: new Date(),
			itemType: null,
			itemDescription: '',
			itemAmount: 0,
			itemLoading: false,
			editItem: null,
			numberShown: 15
		}

		this.loadItems();
	}

	loadItems = () => {
		axios.get('/api/budget').then(res => {
			const { income, offerings } = res.data;
			this.handleItems(income, offerings);
		})
	}

	handleItems = (income, offerings) => {

		income.forEach(i => i.class = 'income');
		offerings.forEach(o => o.class = 'offering');

		const items = [...income, ...offerings];
		items.forEach(x => x.date = new Date(x.date));
		items.sort((a, b) => b.date - a.date);

		// Calculate relevant summary values

		const isOffering = x => x.class === 'offering';
		const isTithing = x => isOffering(x) && x.type === 'Tithing';
		const isFastOffering = x => isOffering(x) && x.type === 'Fast Offering';

		// Get total unpaid tithing
		const setState = this.setState.bind(this);
		let amount = 0;
		from(items).pipe(
			filter(x => !isFastOffering(x)),
			takeWhile(x => !isTithing(x)),
			// @ts-ignore
			scan((acc, x) => acc + x.amount, 0)
		).subscribe({
			next(x) {
				amount = x;
			},
			complete() {
				const tithingOwed = Math.ceil(amount / 10);
				setState({ tithingOwed });
			}
		});

		// last tithing date
		from(items).pipe(
			filter(isTithing),
			take(1),
			map(x => x.date)
		).subscribe(x => {
			this.setState({ lastTithing: x });
		});

		// Last fast offering date
		from(items).pipe(
			filter(isFastOffering),
			take(1)
		).subscribe(x => {
			const { date, amount } = x;
			this.setState({ lastFastOffering: date, lastFastOfferingAmount: amount });
		});

		this.setState({ items: items });

	}

	addItem = () => {
		this.setState({ modalOpen: true });
	}

	saveAddItem = () => {

		const { itemDate: date, itemAmount: amount, itemType, itemDescription } = this.state;

		const item = {
			date,
			amount
		};
		if (!itemType || itemType === 'Income') {
			// @ts-ignore
			item.description = itemDescription;
		} else {
			// @ts-ignore
			item.type = itemType
		}

		this.setState({ itemLoading: true });
		const wait = new Promise(res => setTimeout(res, 1000));
		const save = axios.post('/api/budget', { item });

		return Promise.all([wait, save]).then(() => {
			this.closeModal();
			this.loadItems();
		});
	}

	saveEditItem = () => {
		// TODO make this better, but just delete and re-add for now
		const { editItem } = this.state;
		const { date, amount, type, description } = editItem;
		const addItem = {
			itemDate: date,
			itemAmount: (amount / 100).toFixed(2),
			itemType: type ? type : 'Income',
			itemDescription: description
		};
		this.setState(addItem, async () => {
			await this._delete(editItem);
			this.saveAddItem();
		});

		// console.log( 'editItem:', editItem );
	}

	closeModal = () => {
		this.setState({
			modalOpen: false
		});
		setTimeout(() => {
			this.setState({
				itemDate: new Date(),
				itemType: null,
				itemAmount: 0,
				itemDescription: '',
				itemLoading: false,
				editItem: null
			})
		}, 500);
	}

	onDateChange = date => {
		const { editItem } = this.state;
		if (editItem) {
			this.setState({
				editItem: { ...editItem, date }
			});
		} else {
			this.setState({ itemDate: date });
		}
	}

	onTypeChange = e => {
		const itemType = e.target.value;
		const { editItem } = this.state;
		if (editItem) {
			this.setState({
				editItem: { ...editItem, type: itemType }
			})
		} else {
			this.setState({ itemType });
		}
	}

	onAmountChange = e => {
		const itemAmount = e.target.value;
		const { editItem } = this.state;
		if (editItem) {
			this.setState({
				editItem: { ...editItem, amount: itemAmount * 100 }
			})
		} else {
			this.setState({ itemAmount });
		}
	}

	onDescriptionChange = e => {
		const itemDescription = e.target.value;
		const { editItem } = this.state;
		if (editItem) {
			this.setState({
				editItem: { ...editItem, description: itemDescription }
			});
		} else {
			this.setState({ itemDescription });
		}

	}

	useTithingOwed = () => {
		const amount = (this.state.tithingOwed / 100).toFixed(2);
		this.onAmountChange({
			target: {
				value: amount
			}
		});
	}

	useLastFast = () => {
		const amount = this.state.lastFastOfferingAmount / 100;
		this.onAmountChange({
			target: {
				value: amount
			}
		});
	}

	deleteItem = async item => {
		const shouldDelete = window.confirm('Delete item?');
		if (shouldDelete) {
			await this._delete(item);
			this.loadItems();
		}
	}

	_delete = item => {
		let { _id: id, class: type } = item;
		type = type === 'income' ? type : 'tithe';

		const data = { id, type };
		return axios.post('/api/budget/delete', data);
	}

	editItem = item => {
		this.setState({
			modalOpen: true,
			editItem: item
		});
	}

	render() {

		const {
			items,
			tithingOwed,
			lastTithing,
			lastFastOffering,
			modalOpen,
			itemDate,
			itemAmount,
			itemDescription,
			lastFastOfferingAmount,
			itemLoading,
			editItem,
			numberShown
		} = this.state;


		const formatMoney = num => `$${(num / 100).toFixed(2)}`;
		const dateFormat = 'MM/DD/YY';

		const allEntries = items;
		const truncated = [...items].slice(0, numberShown);
		const notAll = allEntries.length && truncated.length !== allEntries.lengh;

		const entries = truncated.map((x, i) => {
			return (
				<div className={'item shadow ' + x.class} key={i}>
					<div className="date">{format(x.date, dateFormat)}</div>
					<div className="description" onClick={() => this.editItem(x)}>{x.type || x.description || '(empty)'}</div>
					<div className="spacer"></div>
					<div className="amount">{formatMoney(x.amount)}</div>
					<div className="delete" onClick={() => this.deleteItem(x)}>X</div>
				</div>
			)
		});

		const types = ['Income', 'Tithing', 'Fast Offering'];
		let itemType = this.state.itemType || types[0];
		if (editItem) {
			itemType = editItem.type ? editItem.type : types[0];
		}
		let amount = editItem ? (editItem.amount / 100).toFixed(2) : itemAmount;
		let description = editItem ? editItem.description : itemDescription;
		const date = editItem ? editItem.date : itemDate;

		const isIncome = itemType === types[0];
		const isTithing = itemType === types[1];
		const isFast = itemType === types[2];
		const typeInputs = types.map((type, i) => {
			return (
				<label key={i}>
					<input type="radio" name="type" value={type} checked={itemType === type}
						onChange={this.onTypeChange} />
					{type}
				</label>
			)
		});

		const addEditModal = ReactDOM.createPortal(
			<Modal open={modalOpen} onClose={this.closeModal} loading={itemLoading}>
				<div className="title">{editItem ? 'Edit Item' : 'Add Item'}</div>
				<div className="body">
					<div className="item-type">
						{typeInputs}
					</div>
					<div className="row">
						<span className="label">Date</span>
						<span className="spacer"></span>
						<span className="dollar"></span>
						<Datepicker selected={date} onChange={this.onDateChange}></Datepicker>
					</div>
					<div className="row">
						<span className="label">Amount</span>
						<span className="spacer"></span>
						<span className="dollar">$</span>
						<input type="number" value={amount} onChange={this.onAmountChange}></input>
					</div>
					{isIncome &&
						<div className="description">
							<textarea placeholder="Description" value={description} onChange={this.onDescriptionChange}></textarea>
						</div>
					}
					{isTithing &&
						<div className="row">
							<span className="spacer"></span>
							<button onClick={this.useTithingOwed}>Use tithing owed ({formatMoney(tithingOwed)})</button>
						</div>
					}
					{isFast &&
						<div className="row">
							<span className="spacer"></span>
							<button onClick={this.useLastFast}>Use last amount ({formatMoney(lastFastOfferingAmount)})</button>
						</div>
					}
				</div>
				<div className="buttons">
					<button className="primary" disabled={!amount || (isIncome && !description)}
						onClick={editItem ? this.saveEditItem : this.saveAddItem}>
						{ editItem ? 'Save' : 'Add' }
					</button>
				</div>
			</Modal>,
			// @ts-ignore
			document.getElementById( 'modal' )
		);

		return (
			<div id="budget">
				{addEditModal}
				<div className="entries">
					<div className="items">
						{entries}
						{ notAll &&
							<div className="more-container">
								<button className="more" onClick={() => this.setState({ numberShown: numberShown + 25 })}>Show more</button>
							</div>
						}
					</div>
				</div>
				<div className="summary">
					<div className="container">
						<div>
							<div>Tithing Owed:</div>
							<div className="spacer"></div>
							<div>{formatMoney(tithingOwed)}</div>
						</div>
						<div>
							<div>Last Tithing:</div>
							<div className="spacer"></div>
							<div>{format(lastTithing, dateFormat)}</div>
						</div>
						<div>
							<div>Last Fast Offering:</div>
							<div className="spacer"></div>
							<div>{format(lastFastOffering, dateFormat)}</div>
						</div>
					</div>
					<button className="shadow new" onClick={this.addItem}>Add Item</button>
				</div>
			</div>
		);
	}
}


export default Budget;
