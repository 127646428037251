import React from 'react';
import { Route } from 'react-router';

import Login from './components/Login';
import Budget from './components/Budget';
import TopBar from './components/TopBar';
import { CustomBrowserRouter } from './components/Router';
import './App.scss';
import { useAuthentication } from './hooks/useAuthentication';
import useRouter from './hooks/useRouter';
import Choir from './components/Choir';

const Page = (child) => {

	const router = useRouter();

	useAuthentication(() => {

	}, () => {
		router.history.push('/');
	});

	return (
		<React.Fragment>
			<TopBar></TopBar>
			<div className="App logged-in">
				{ child }
			</div>
		</React.Fragment>
	)
};

const BudgetPage = () => Page(<Budget></Budget>);
const ChoirPage = () => Page(<Choir></Choir>)

function App() {
	return (
		<CustomBrowserRouter>
			<Route exact path="/" component={Login}></Route>
			<Route path="/budget" component={BudgetPage}></Route>
			<Route path="/choir" component={ChoirPage}></Route>
		</CustomBrowserRouter>
	);
}

export default App;
