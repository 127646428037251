import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

class Modal extends Component<any, any> {

	clickOutside = e => {
		this.props.onClose( e );
	}

	bodyClick = e => {
		e.stopPropagation();
	}

	render() {
		const { open, loading } = this.props;
		const classes = open ? 'open' : '';
		return (
			<div id="modal-overlay" className={classes} onClick={this.clickOutside}>
				<div id="modal-body" onClick={this.bodyClick}>
					{loading &&
						<div id="loader-container">
							<div id="loader-bar"></div>
						</div>
					}
					<div id="modal-body-wrapper">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

// @ts-ignore
Modal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	children: PropTypes.any,
	loading: PropTypes.bool
};

export default Modal;
