import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Budget from './Budget';

import useRouter from '../hooks/useRouter';
import { useAuthentication } from '../hooks/useAuthentication';

function Main() {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isSubmitting, setSubmitting] = useState(false);
	const router = useRouter();

	useAuthentication(() => {
		console.log('auth')
		router.history.push('/budget');
	}, () => {
		console.log('no auth')
		router.history.push('/');
	});

	useEffect(() => {
		document.title = 'Austinea';
	}, []);

	useEffect(() => {
		if (isSubmitting) {
			axios.post('/api/login', { username, password }).then(res => {
				const { token } = res.data;
				localStorage.setItem('token', token);
				setSubmitting(false);
				router.history.push('/budget');
			}).catch(() => {
				console.log( 'invalid' );
				setSubmitting(false);
			});
		}
	}, [isSubmitting, username, password, router.history]);

	const onEnterPress = e => {
		if(e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			setSubmitting(true);
		}
	}

	const onSubmit = e => {
		e.preventDefault();
		setSubmitting(true);
	}

	const login = (
		<div className="login">
			<form onSubmit={onSubmit}>
				<input id="username" type="text" value={username} placeholder="username"
					onChange={e => setUsername(e.target.value)}>
				</input>
				<input id="password" type="password" value={password} placeholder="password"
					onChange={e => setPassword(e.target.value)} onKeyDown={onEnterPress}>
				</input>
				<button type="submit">Login</button>
			</form>
		</div>
	);

		let appClasses = ['App'];
		return (
			<React.Fragment>
				<div className={appClasses.join(' ')} key="app">
					{ login }
				</div>
			</React.Fragment>
		);

};

export default Main;
