import React, { useEffect, useState } from 'react';
import useRouter from '../hooks/useRouter';

import './TopBar.scss';

interface PageLink {
	name: string
	route: string
	isActive?: boolean
}

function TopBar() {

	const router = useRouter();
	const [links, setLinks] = useState<PageLink[]>([
		{ name: 'Budget', route: '/budget' },
		{ name: 'Choir', route: '/choir' },
	]);

	useEffect(() => {
		const name = router.location.pathname;
		setLinks(links.map(l => {
			l.isActive = l.route === name;
			return l;
		}));
		const activePage = links.find(l => l.isActive);
		if (activePage) {
			document.title = `Austinea - ${activePage.name}`
		}
	}, [])

	const logout = () => {
		delete localStorage.token;
		router.history.push('/');
	}

	const goto = route => router.history.push(route);

	const linkEls = links.map((l, i) => (
		<button key={i} className={l.isActive ? 'active': ''} onClick={() => goto(l.route)}>{l.name}</button>
	));

	return (
		<div className="top" key="top">
			<div className="links">
				{ linkEls }
			</div>
			<div className="spacer"></div>
			<button onClick={logout}>Logout</button>
		</div>
	);

};

export default TopBar;
